// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .highlight {
  height: 40px;
  display: block;
  padding: 10px 8px;
}
:host .emails-list {
  max-width: 50%;
}
:host ::ng-deep .ng-select {
  padding: 0;
  max-width: 500px;
  min-width: 320px;
}
:host ::ng-deep .ng-select .ng-select-container .ng-value {
  margin: 4px;
}
:host ::ng-deep .ng-select .ng-select-container .ng-placeholder {
  padding: 0px 9px !important;
  line-height: 2.4em !important;
}
:host ::ng-deep .ng-select .ng-select-container:hover {
  box-shadow: none;
}
:host .doc ::ng-deep table {
  border-color: #bfc7cd;
  border-collapse: collapse;
}
:host .doc ::ng-deep table[cellpadding="1"] thead,
:host .doc ::ng-deep table[cellpadding="1"] tbody,
:host .doc ::ng-deep table[cellpadding="1"] tfoot,
:host .doc ::ng-deep table[cellpadding="1"] tr,
:host .doc ::ng-deep table[cellpadding="1"] td,
:host .doc ::ng-deep table[cellpadding="1"] th {
  padding: 4px;
}
:host .doc ::ng-deep table[border="1"] thead,
:host .doc ::ng-deep table[border="1"] tbody,
:host .doc ::ng-deep table[border="1"] tfoot,
:host .doc ::ng-deep table[border="1"] tr,
:host .doc ::ng-deep table[border="1"] td,
:host .doc ::ng-deep table[border="1"] th {
  border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/document-viewer/document-viewer.component.scss"],"names":[],"mappings":"AAGI;EACI,YAAA;EACA,cAAA;EACA,iBAAA;AAFR;AAII;EACI,cAAA;AAFR;AAKQ;EACI,UAAA;EACA,gBAAA;EACA,gBAAA;AAHZ;AAMgB;EACI,WAAA;AAJpB;AAOgB;EACI,2BAAA;EACA,6BAAA;AALpB;AAQgB;EACI,gBAAA;AANpB;AAcY;EACI,qBAAA;EACA,yBAAA;AAZhB;AAgBgB;;;;;;EAMI,YAAA;AAdpB;AAmBgB;;;;;;EAMI,iBAAA;AAjBpB","sourcesContent":["@import \"var\";\n\n:host {\n    .highlight {\n        height: 40px;\n        display: block;\n        padding: 10px 8px;\n    }\n    .emails-list {\n        max-width: 50%;\n    }\n    ::ng-deep {\n        .ng-select {\n            padding: 0;\n            max-width: 500px;\n            min-width: 320px;            \n\n            .ng-select-container {\n                .ng-value {\n                    margin: 4px;\n                }\n\n                .ng-placeholder {\n                    padding: 0px 9px !important;\n                    line-height: 2.4em !important;\n                }\n\n                &:hover {\n                    box-shadow: none;\n                }\n            }\n        }\n    }\n\n    .doc {\n        ::ng-deep {\n            table {\n                border-color: #bfc7cd;\n                border-collapse: collapse;\n            }\n\n            table[cellpadding=\"1\"] {\n                thead,\n                tbody,\n                tfoot,\n                tr,\n                td,\n                th {\n                    padding: 4px;\n                }\n            }\n\n            table[border=\"1\"] {\n                thead,\n                tbody,\n                tfoot,\n                tr,\n                td,\n                th {\n                    border-width: 1px;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
