import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { CreateInvoiceInput, Order, OrderBasicDetail, OrderPriorityEnum, OrderStatusEnum, OrderUpdateType, OrderUpdateTypeEnum } from '@app/models/order.model';
import { Invoice } from '@app/models/invoice.model';
import { Route } from '@app/models/route.model';
import { OrderTrip } from '@app/models/order-trip.model';
import { ShipmentType } from '@app/models/shipment-type.model';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { Address } from '@app/models/address.model';
import { OrderStop, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { chain, cloneDeep, filter, find, map, omit } from 'lodash';
import { HelperService } from './helper.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ListTabsService } from '@app/@shared/list-tabs/list-tabs.service';


@Injectable({
    providedIn: 'root'
})
export class OrderService {

    private basePath = 'Orders'

    dataStore: {
        order?: Order
    } = {}

    private cancelRequestOrderBasicDetails$ = new Subject<void>();
    private _order: BehaviorSubject<Order>;
    private _orderStopPriority: BehaviorSubject<{ orderStopId: string | number, priority: OrderPriorityEnum }>;
    private _orderDatatableRefresh: BehaviorSubject<boolean>;


    reloadOrderAttachments: BehaviorSubject<any>;
    get reloadOrderAttachments$() {
        return this.reloadOrderAttachments.asObservable();
    };

    get order(): Order {
        return this.dataStore.order;
    }
    set order(value: Order) {
        this._order.next(value);
    }
    get order$() {
        return this._order.asObservable();
    };

    set orderStopPriority(value: { orderStopId: string | number, priority: OrderPriorityEnum }) {
        this._orderStopPriority.next(value);
    }
    get orderStopPriority$(): Observable<{ orderStopId: string | number, priority: OrderPriorityEnum }> {
        return this._orderStopPriority.asObservable();
    };

    set orderDatatableRefresh(value: boolean) {
        this._orderDatatableRefresh.next(value);
    }
    get orderDatatableRefresh$(): Observable<boolean> {
        return this._orderDatatableRefresh.asObservable();
    };

    reloadRoutesData: BehaviorSubject<boolean | 'update'>;
    get reloadRoutesData$() {
        return this.reloadRoutesData.asObservable();
    };

    updateCacheData: BehaviorSubject<any>;
    get updateCacheData$() {
        return this.updateCacheData.asObservable();
    };

    constructor(
        private http: HttpClient,
        private router: Router,
        private helperService: HelperService,
        private listTabsService: ListTabsService,
    ) {
        this._order = new BehaviorSubject(null)
        this._orderStopPriority = new BehaviorSubject(null)
        this._orderDatatableRefresh = new BehaviorSubject(false)
        this.reloadOrderAttachments = new BehaviorSubject(null)
        this.reloadRoutesData = new BehaviorSubject(false)
        this.updateCacheData = new BehaviorSubject(null)
    }

    get(id: string | number) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/${id}`).toPromise()
            .then((({ data }) => data));
    }

    getSentEmails(orderId: string | number, request = { suggestCustomerPastSentEmails: false }) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/${orderId}/SentEmails`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    getOrderContacts(orderId) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/${orderId}/contacts`)).then((({ data }) => data));
    }

    getAll(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<Order>>(`${this.basePath}/OrderList`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    getOrderStatistic(request?: ListRequest) {
        return this.http.get<ApiResponse<any>>(`OrderStatistic/DailyOrderNumbers`).toPromise()
            .then((({ data }) => data));
    }

    getOrderBasicDetails(request?: ListRequest) {
        return new Promise<ApiPaginationData<OrderBasicDetail>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<OrderBasicDetail>>(`${this.basePath}/OrderListBasicDetails`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestOrderBasicDetails$)
                )
                .subscribe(
                    ({ data }: any) => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestOrderBasicDetails() {
        this.cancelRequestOrderBasicDetails$.next();
    }

    getActiveOrderStops(orderId: string | number) {
        return this.http.get<ApiResponse<OrderStop[]>>(`${this.basePath}/${orderId}/Stops`, {}).toPromise()
            .then((({ data }) => data));
    }

    getStats(request?: Partial<any>) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/Stats`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    create(request?: Partial<Order>) {
        return this.http.post<ApiResponse<any>>(`Quotes`, request).toPromise()
            .then((({ data }) => data));
    }

    createOrderStop(orderId, request?: Partial<OrderStop>) {
        return this.http.post<ApiResponse<any>>(`Quotes/${orderId}/OrderStop`, request).toPromise()
            .then((({ data }) => data));
    }

    update(id: string | number, request?: Partial<Order>, updateType?: OrderUpdateType, returnQuote = true, 
        completeQuoteRouteItems = false, createInvoice = true) {
        return this.http.put<ApiResponse<any>>(
            `Quotes/${id}/${updateType}?returnQuote=${returnQuote}&completeQuoteRouteItems=${completeQuoteRouteItems}&createInvoice=${createInvoice}`,
            request).toPromise()
            .then((({ data, otherMessages }) => {
                if (otherMessages?.length > 0) {
                    this.helperService.addToast(otherMessages[0]?.message, '', 'warning');
                }
                return data;
            }));
    }

    closeOrder(orderId: string | number) {
        return this.http.put<ApiResponse<Invoice>>(`${this.basePath}/${orderId}/close`, {}).toPromise()
            .then((({ data }) => data));
    }

    updateOrderPriority(orderId: string | number, priority: OrderPriorityEnum) {
        return this.http.put<ApiResponse<Invoice>>(`${this.basePath}/${orderId}/priority/${priority}`, { priority: priority }).toPromise()
            .then((({ data }) => {
               
                return data
            }));
    }

    updateOrderStopPriority(orderStopId: string | number, priority: OrderPriorityEnum) {
        return this.http.put<ApiResponse<Invoice>>(`Quotes/${orderStopId}/priority/${priority}`, { priority: priority }).toPromise()
            .then((({ data }) => {
                this.orderStopPriority = { orderStopId, priority };
                return data
            }));
    }

    createInvoice(orderId: string | number, closeOrder?: boolean, request: CreateInvoiceInput = {}) {
        return this.http.put<ApiResponse<Invoice>>(`${this.basePath}/${orderId}/${closeOrder ? 'close-and-create-invoice' : 'invoice'}`, request).toPromise()
            .then((({ data }) => data));
    }

    deleteInvoice(orderId: string | number){
        return this.http.delete<any>(`${this.basePath}/${orderId}/invoice`).toPromise()
        .then((({ data }) => data));
    }

    getOrderTrips(orderId: string | number) {
        return this.http.get<ApiResponse<OrderTrip[]>>(`${this.basePath}/${orderId}/trips`).toPromise()
            .then((({ data }) => data));
    }

    revertOrderToQuote(orderId: string | number) {
        return this.http.put<ApiResponse<Invoice>>(`${this.basePath}/${orderId}/revert`, {}).toPromise()
            .then((({ data }) => data));
    }

    undoOrderStopPickupDelivery(orderStopId: string | number) {
        return this.http.put<ApiResponse<Invoice>>(`Quotes/${orderStopId}/UndoOrderStop`, {}).toPromise()
            .then((({ data }) => data));
    }

    testPricing(entity: ShipmentType, stop: OrderItemStop, testHours: number, testMiles: number, tollTagCost: number, tollCount: number) {
        return this.http.post<ApiResponse<Order>>(`${this.basePath}/testPricing`, {
            tollCount,
            tollTagCost,
            shipmentType: entity,
            orderItemStop: stop,
            hours: testHours,
            miles: testMiles,
        }).toPromise()
            .then((({ data }) => data));
    }

    /**
     * Search addresses from orders
     * 
     * @param request 
     * @returns 
     */
    searchOrderAddresses(request?: ListRequest) {
        return this.http.get<ApiResponse<Address[]>>(`${this.basePath}/Address`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    async saveOrder(order: Order, updateType?: OrderUpdateType) {

        // Get Order Items from OrderStops
        order.orderItems = chain(order.orderStops)
            .filter((orderStop) => orderStop.orderStopType == OrderStopTypeEnum.PICKUP)
            .flatMap((orderStop) => {
                return map(orderStop.orderItemStops, 'orderItem')
            })
            .filter((item) => Boolean(item))
            .map((orderItem) => {
                const itemStops = chain(order.orderStops)
                    .flatMap((orderStop) => orderStop.orderItemStops)
                    .filter((orderItemStops) => {
                        return orderItemStops.orderItemId === orderItem.orderItemId
                    })
                    .map((orderItemStop) => {
                        // Find Order stop and remove orderItemStops then set order stop in OrderItemStop
                        const stop = chain(order.orderStops)
                            .find({ orderStopId: orderItemStop.orderStopId })
                            .omit(['orderItemStops'])
                            .value()

                        orderItemStop.orderStop = stop
                        return omit(orderItemStop, 'orderItem')
                    })
                    .value()

                if (orderItem.orderItemId) {
                    orderItem.orderItemStops = itemStops
                } else {
                    // Remove OrderItemStops for new OrderItems because giving error form backend
                    delete orderItem.orderItemStops;
                }
                return orderItem;
            })
            .value()
        if (!updateType) {
            if (order.orderStops?.length > 0) {
                updateType = OrderUpdateTypeEnum.DeliveryDone;
            } else {
                updateType = OrderUpdateTypeEnum.PickupDone;
            }
        }

        if (order.orderId) {
            const data = await this.update(order.orderId, order, updateType, true, order.completeQuoteRouteItems, order?.isUpdateInvoice === true);

            if (order?.isUpdateInvoice) {
                try {             
                    const updatedInvoice = await this.createInvoice(this.order?.orderId, false, { isReplace: true });
                    data.invoice = await updatedInvoice;
                    await this.listTabsService.closeTab('accounting', `${order?.invoice?.invoiceId}`);
                } catch (error) {
                    this.helperService.errorMessage(error)
                }
            }

            this.order = data;
            this.orderDatatableRefresh = true;

            if (order?.isApproveQuote) {
                this.helperService.successMessage("Quote successfully approved and moved to Orders.");
                if (order?.isApprovedQuoteRedirectToOrder) {
                    this.router.navigate(['/app/orders'], { queryParams: { id: order?.orderId, closeTabId: order?.orderId } });              
                } else {
                    this.router.navigate(['/app/quotes'], { queryParams: { closeTabId: order?.orderId } });  
                }
            } else if (order?.isDispatchRouteStopItem) {
                this.helperService.successMessage("Route item stop successfully updated.");
            } else {
                if (order.orderStatus === OrderStatusEnum.QUOTE || order.orderStatus === OrderStatusEnum.DRAFT) {
                    this.helperService.successMessage("Quote successfully updated.");
                }
                else {
                    this.helperService.successMessage("Order successfully updated.");
                }

            }

            if(order?.isCloseTab) {
                this.router.navigate([this.router?.url], { queryParams: { closeTabId: order?.orderId } })
            }

            return data;

        } else {
            if (!order.customer) {
                this.helperService.errorMessage('Please select customer')
                return;
            }

            const data = await this.create(order);
            this.order = data;

            if (data.orderStatus === OrderStatusEnum.QUOTE || data.orderStatus === OrderStatusEnum.DRAFT) {
                this.helperService.successMessage("Quote successfully Created.");
            }
            else {
                this.helperService.successMessage("Order successfully Created.");
            }

            if (!order?.isOrder && data?.orderStatus === OrderStatusEnum.DRAFT) {
                this.router.navigate(['app/quotes'], { queryParams: { id: data.orderId } });
            } else {
                this.router.navigate(['app/orders'], { queryParams: { id: data.orderId } });
            }

            return data;
        }

    }


    updateQtyToOrderStop(order: Order, orderStopId: number, orderItemId: number, qty: number) {
        const orderStop = find(order.orderStops, (orderStop) => orderStop.orderStopId == orderStopId)
        const orderItemStop = find(orderStop.orderItemStops, ({ orderItem }) => orderItem.orderItemId == orderItemId)

        if (orderStop.orderStopType === OrderStopTypeEnum.DELIVERY) {
            const totalQuantity = this.getTotalQuantity(order, orderItemId)
            const pickedQuantity = this.getPickedQty(order, orderItemId)
            const pickedQuantityInOrderStop = this.getPickedQty(order, orderItemId, orderStopId)
            const newQuantity = pickedQuantity + qty - pickedQuantityInOrderStop;

            if (totalQuantity < newQuantity) {
                throw new Error("Item quantity cannot be more the Pickup quantity");
            }
        }
        this.updateOrderItemStopQuantity(order, orderItemStop, qty);
    }

    addQtyToOrderStop(order: Order, orderStopId: number, orderItemId: number, qty: number) {
        const orderStop = find(order.orderStops, (orderStop) => orderStop.orderStopId == orderStopId)
        const orderItemStop = find(orderStop?.orderItemStops, ({ orderItem }) => orderItem?.orderItemId == orderItemId)
        const totalQty = (orderItemStop.quantity || 0) + qty;
        orderItemStop.isActive = totalQty > 0 ? true : false;
        this.updateQtyToOrderStop(order, orderStopId, orderItemId, totalQty)
    }

    creteOrderStopItemIfNotExists(order: Order, orderStopId: number, orderItemStop: OrderItemStop, qty: number = 0) {
        const orderStop = find(order.orderStops, (orderStop) => orderStop.orderStopId == orderStopId)

        const newItemStop = omit(cloneDeep(orderItemStop), 'orderItemStopId');
        newItemStop.isActive = qty > 0 ? true : false;
        newItemStop.quantity = qty;
        orderStop.orderItemStops.push(newItemStop)

        return newItemStop;
    }


    addOrderItemStopToOrderStop(order: Order, orderItemStop: OrderItemStop, newOrderStopId: number) {
        const oldOrderStop = find(order.orderStops, ({ orderStopId }) => orderStopId == orderItemStop.orderStop?.orderStopId)
        const newOrderStop = find(order.orderStops, ({ orderStopId }) => orderStopId == newOrderStopId)

        const pendingQty = this.getPendingQty(order, orderItemStop.orderItem.orderItemId)

        let removeOldItem = false
        if (oldOrderStop?.orderStopType === OrderStopTypeEnum.DELIVERY && newOrderStop.orderStopType === OrderStopTypeEnum.DELIVERY) {
            removeOldItem = true
        }

        // Check orderStop has same orderItem, if yes then update or add item in delivery
        const hasSameItem = find(newOrderStop.orderItemStops, ({ orderItem }) => orderItem.orderItemId == orderItemStop.orderItem?.orderItemId)

        if (hasSameItem) {
            if (removeOldItem) {
                // Remove orderItemStop if move item form delivery to delivery
                this.removeItemFormOrderStop(order, oldOrderStop.orderStopId, hasSameItem.orderItem.orderItemId)
            }
            const quantity = hasSameItem.quantity + pendingQty
            // Update Order Item Stop Quantity and sync nested object i.e OrderItem
            this.updateOrderItemStopQuantity(order, hasSameItem, quantity);
        } else {
            if (removeOldItem) {
                // Remove orderItemStop if move item form delivery to delivery
                this.removeItemFormOrderStop(order, orderItemStop.orderStop.orderStopId, orderItemStop.orderItem.orderItemId)
            }
            const newItemStop = omit(cloneDeep(orderItemStop), 'orderItemStopId');
            newItemStop.isActive = true;
            newItemStop.quantity = pendingQty;
            newOrderStop.orderItemStops.push(newItemStop)

            // Update Pickup Order Item Qty
            this.syncPickupOrderItemQuantity(order, orderItemStop.orderItem.orderItemId);
        }
    }

    updateOrderItemStopQuantity(order: Order, orderItemStop: OrderItemStop, qty) {
        const orderStop = find(order.orderStops, { orderStopId: orderItemStop.orderStopId });

        if (qty > 0) {
            const totalQuantity = this.getTotalQuantity(order, orderItemStop.orderItem.orderItemId)
            const pickedQuantity = this.getPickedQty(order, orderItemStop.orderItem.orderItemId)

            if (orderStop.orderStopType === OrderStopTypeEnum.DELIVERY) {
                if ((pickedQuantity + qty - orderItemStop.quantity) > totalQuantity) {
                    throw new Error("Item quantity cannot be more the Pickup quantity");
                }
            }
            orderItemStop.quantity = qty

            // Sync Pickup Order Item Quantity i,e pickQuantity, totalQuantity, pickedQuantity, quantityToPick
            this.syncPickupOrderItemQuantity(order, orderItemStop.orderItem.orderItemId);
        } else {
            this.removeItemFormOrderStop(order, orderItemStop.orderStop.orderStopId, orderItemStop.orderItem.orderItemId)
        }

        let pendingQty = 0

        // Reduce quantity in Delivery when reduce in pickup
        pendingQty = this.getPendingQty(order, orderItemStop.orderItem.orderItemId)
        if (orderStop.orderStopType === OrderStopTypeEnum.PICKUP && pendingQty < 0) {
            let i = order.orderStops.length
            do {
                pendingQty = this.getPendingQty(order, orderItemStop.orderItem.orderItemId)

                const deliveryOrderStop = chain(order.orderStops)
                    .filter((orderStop) => {
                        const foundItem = orderStop.orderItemStops.filter(({ orderItem, isActive }) => {
                            return isActive && orderItem.orderItemId == orderItemStop.orderItem.orderItemId
                        })
                        return orderStop.isActive && orderStop.orderStopType === OrderStopTypeEnum.DELIVERY && foundItem?.length > 0
                    })
                    .first()
                    .value()

                const stop = deliveryOrderStop.orderItemStops.filter(({ orderItem, isActive }) => {
                    return isActive && orderItem.orderItemId == orderItemStop.orderItem.orderItemId;
                })[0]
                this.updateOrderItemStopQuantity(order, stop, stop.quantity - 1)
                pendingQty = this.getPendingQty(order, orderItemStop.orderItem.orderItemId)
                i--;
            } while (pendingQty < 0 && i >= 0);
        }



    }


    getPickupStopByItemId(order: Order, orderItemId: number) {
        for (let index = 0; index < order.orderStops.length; index++) {
            const orderStop = order.orderStops[index];
            for (let orderStopIndex = 0; orderStopIndex < orderStop.orderItemStops.length; orderStopIndex++) {
                const orderItemStop = orderStop.orderItemStops[orderStopIndex];
                if (orderItemStop.orderItem.orderItemId == orderItemId) {
                    return orderItemStop;
                }
            }
        }
    }

    syncPickupOrderItemQuantity(order: Order, orderItemId: number) {
        const orderItemStop = this.getPickupStopByItemId(order, orderItemId)
        if (orderItemStop) {
            const totalQuantity = this.getTotalQuantity(order, orderItemStop.orderItem.orderItemId)
            const pickedQuantity = this.getPickedQty(order, orderItemStop.orderItem.orderItemId)

            // orderItemStop.qtyAssinedToTrip = orderItemStop.quantity
            orderItemStop.orderItem.pickQuantity = orderItemStop.quantity
            orderItemStop.orderItem.totalQuantity = totalQuantity
            orderItemStop.orderItem.pickedQuantity = pickedQuantity
            orderItemStop.orderItem.quantityToPick = totalQuantity - pickedQuantity
        }

        return orderItemStop;
    }

    getMaxQuantityToPick(order: Order, orderItemId: number, orderStopId: number) {
        const totalQuantity = this.getTotalQuantity(order, orderItemId);
        const pickedQuantity = this.getPickedQty(order, orderItemId);
        const orderStop = find(order.orderStops, { orderStopId: orderStopId })
        const pickedByStop = chain(orderStop.orderItemStops)
            .filter((orderItemStop) => orderItemStop.orderItemId === orderItemId)
            .sumBy('quantity')
            .value()

        return totalQuantity - pickedQuantity + pickedByStop;
    }

    removeItemFormOrderStop(order: Order, orderStopId: number, removeOrderItemId?: number) {
        const orderStop = find(order.orderStops, { orderStopId })
        const orderItemStop = find(orderStop.orderItemStops, ({ orderItem }) => orderItem.orderItemId == removeOrderItemId)

        if (orderItemStop) {
            orderItemStop.quantity = 0
            orderItemStop.isActive = false;
        }

        if (orderStop.orderStopType === OrderStopTypeEnum.PICKUP) {
            const deliveries = filter(order.orderStops, { orderStopType: OrderStopTypeEnum.DELIVERY })
            deliveries.forEach((orderStop) => {
                orderStop.orderItemStops?.forEach((orderItemStop) => {
                    if (orderItemStop.orderItem.orderItemId === removeOrderItemId) {
                        orderItemStop.isActive = false
                    }
                })
            })
        }

        this.syncPickupOrderItemQuantity(order, removeOrderItemId);
    }

    removeOrderStop(order: Order, orderStopId: number) {
        const orderStop = find(order.orderStops, { orderStopId })
        orderStop.isActive = false;

        orderStop.orderItemStops.forEach((orderItemStop) => {
            this.removeItemFormOrderStop(order, orderStopId, orderItemStop.orderItemStopId)
        });

        // if (orderStop.orderStopType === OrderStopTypeEnum.DELIVERY) {
        //     orderStop.orderItemStops?.forEach((orderItemStop) => {
        //         orderItemStop.isActive = false
        //         this.syncPickupOrderItemQuantity(order, orderItemStop.orderItem.orderItemId);
        //     })
        // } else {
        //     orderStop.orderItemStops.forEach((orderItemStop) => {
        //         this.removeItemFormOrderStop(order, orderStopId, orderItemStop.orderItemStopId)
        //     });
        // }
    }

    getTotalQuantity(order: Order, orderItemId: number) {
        const qty = chain(order.orderStops)
            .filter((orderStop) => orderStop.isActive && orderStop.orderStopType === OrderStopTypeEnum.PICKUP)
            .map((orderStop) => {
                return orderStop.orderItemStops?.filter((item) => {
                    return item.isActive && item.orderItem?.orderItemId === orderItemId
                }).map((item) => item.quantity)
            })
            .flatten()
            .sum()
            .value()

        return qty;
    }

    getPendingQty(order: Order, orderItemId: number) {
        const total = this.getTotalQuantity(order, orderItemId)
        const picked = this.getPickedQty(order, orderItemId)

        return total - picked;
    }

    getPickedQty(order: Order, orderItemId: number, orderStopId?: number) {
        const qty = chain(order.orderStops)
            .filter((orderStop) => orderStop.isActive && orderStop.orderStopType === OrderStopTypeEnum.DELIVERY)
            .map((orderStop) => {
                if (orderStopId && orderStopId != orderStop.orderStopId) {
                    return [];
                }
                return orderStop.orderItemStops?.filter((item) => {
                    return item.isActive && item.orderItem?.orderItemId === orderItemId
                }).map((item) => item.quantity)
            })
            .flatten()
            .sum()
            .value()

        return qty;
    }

    getRecentUsedAddresses(customerId: number, count: number = 100) {
        return this.http.get<ApiResponse<Address[]>>(`Quotes/RecentUsedAddresses`, { params: {customerId, count} }).toPromise()
            .then((({ data }) => data));
    }

    getOrderActivities(orderId: string | number, request: any = {}) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/${orderId}/Activities`, { params: request })).then((({ data }) => data));
    }

    getOrderStopsWarnings(orderId: string | number, request: any = {}) {
        return firstValueFrom(this.http.get<ApiResponse<any>>(`${this.basePath}/${orderId}/OrderStopsWarnings`, { params: request })).then((({ data }) => data));
    }

    getRecentUsedCustomerContact(customerId: number = 10) {
        return this.http.get<ApiResponse<Address[]>>(`Quotes/RecentUsedCustomerContact`, { params: {customerId} }).toPromise()
            .then((({ data }) => data));
    }

    validateQuoteWarehouses(pickup: OrderStop, delivery: OrderStop) {
        return this.http.post<ApiResponse<any>>(`Quotes/ValidateQuoteWarehouses`, {pickup, delivery}).toPromise()
            .then((({ data }) => data));
    }

    // Function to reset the state to initial values
    resetState() {
        this.order = null
    }
}
