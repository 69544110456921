import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Route, Routes } from '@models/route.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutesService } from '@app/shared/services/router.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UtilService } from '@app/services/util.service';
import moment = require('moment');
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteStopGroup } from '@app/models/route-stop-group.model';
import { cloneDeep } from 'lodash';

@UntilDestroy()
@Component({
    selector: 'app-add-unassigned-stops',
    templateUrl: './add-unassigned-stops.component.html',
    styleUrls: ['./add-unassigned-stops.component.scss']
})
export class AddUnassignedStopsComponent implements OnInit {
    @ViewChild('template') template: TemplateRef<any>;
    isLoading = true
    // unassignedStops: RouteBoxUIGroup[] = [];
    @Input() search: any = {};
    date: Date = null;

    unassignedRoutes: RouteBoxUIGroup[] = [];
    unassignedRoutes$: Subject<any> = new Subject();
    hasMorePage: boolean;
    routeStopGroups: RouteStopGroup[];;

    constructor(
        public helperService: HelperService,
        public activeModal: NgbActiveModal,
        public routesService: RoutesService,
        public util: UtilService,
    ) {
    }

    private _route: Route;
    initialRoute: Route;

    @Input() set route(value: Route) {
        this._route = cloneDeep(value);
        this.initialRoute = this.route;
        if (this.initialRoute) {
            this.getRouteItemGroups();
        }
    }

    get route() {
        return this._route
    }

    private _routes: Routes[];

    @Input() set routes(value: Routes[]) {
        this._routes = cloneDeep(value);
    }

    get routes() {
        return this._routes
    }

    ngOnInit() {

        this.unassignedRoutes$
            .pipe(
                debounceTime(500),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {
                request = Object.assign({}, this.search, request)
                try {
                    const unassignedRoutes: any = await this.routesService.getUnassignedRoutes(request, false)
                    this.hasMorePage = unassignedRoutes.totalCount > (request.page * request.itemsPerPage);
                    if (unassignedRoutes.page > 1) {
                        this.unassignedRoutes = this.unassignedRoutes.concat(RouteBoxUIGroup.createGroups(unassignedRoutes?.list));
                    } else {
                        this.unassignedRoutes = RouteBoxUIGroup.createGroups(unassignedRoutes?.list);
                    }
                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    this.helperService.errorMessage(error)
                }
            });

        this.unassignedRoutes$.next(true)

        this.getRouteItemGroups();
        
    }

    loadMoreCustomer() {
        this.isLoading = true
        this.search.page++;
        this.unassignedRoutes$.next(true)
    }

    routeComparer(a: Route, b: Route) {
        return (a == null && b == null) || a?.routeId === b?.routeId;
    }

    getRouteItemGroups() {
        const search = {
            routeId: this.initialRoute.routeId,
            status: 0,
        }
        this.routeStopGroups = cloneDeep(this.initialRoute.routeStopGroups)
        this.routesService.getRouteItemGroups(search).then((res) => {
            this.initialRoute.routeStopGroups = cloneDeep(res?.list);
        }).catch((error) => {
            this.helperService.errorMessage(error)
        })
    }

    handleDateChange(date: string | Date) {
        this.isLoading = true
        this.unassignedRoutes = []
        if (date) {
            this.search.DateRange = [moment(date).startOf('day').toISOString(), moment(date).endOf('day').toISOString()];
        } else {
            this.search.DateRange = [];
        }
        this.search.page = 1
        this.unassignedRoutes$.next(true)
    }

    close() {
        this.activeModal.close();
    }

    isAllChecked() {
        return this.unassignedRoutes && this.unassignedRoutes.every(t => t.items.every(i => i.selected));
    }

    checkAllItems(e, stop) {
        stop.items.forEach(t => t.selected = e.target.checked)
    }

    isAllItemsChecked(stop) {
        return stop.items.every(t => t.selected);
    }

    checkAll(e) {
        this.unassignedRoutes.forEach(t => t.items.forEach(i => i.selected = e.target.checked))
    }

    checkAllByOrder($event, orderId: number) {
        for (const it of this.unassignedRoutes.filter(st => st.orderId === orderId).flatMap(st => st.items)) {
            it.selected = $event.target.checked;
        }
    }

    changeQtyDown(selectedStopItem) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 0;
        } else {
            selectedStopItem.pickQuantity = Math.max(0, selectedStopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(selectedStopItem, allowUp = false) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 1;
        } else {
            if ((selectedStopItem.pickQuantity < selectedStopItem.quantityToPick) || allowUp) {
                selectedStopItem.pickQuantity++;
            }
        }
    }

    saveStop() {
        // get all stopes with at least one item added
        const stops = this.unassignedRoutes.filter(t => t.items.some(i => i.selected));

        if (!stops?.length) {
            this.helperService.errorMessage(`Please select ${this.search?.StopType ? (this.search?.StopType == 'Pickup' ? 'a pick up' : 'a delivery') : 'an order'} first`)
            return;
        }

        this.helperService.isLoading = true
        // process each stop
        for (const stop of stops) {
            // process Pickup
            for (const box of stop.pickupRouteBoxes.filter(b => b != null)) {
                this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
            }
            // process DropOff
            for (const box of stop.dropOffRouteBoxes.filter(b => b != null)) {
                this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
            }
        }
        this.routesService.updateRouteStops(this.initialRoute.routeId, this.initialRoute.routeStopGroups).then((res) => {
            this.routesService.reloadRoute = { routeId: this.initialRoute.routeId }
            this.routesService.reloadUnassigned = true
            this.helperService.successMessage("Route stops successfully updated.")
            this.activeModal.close(true);
            this.helperService.isLoading = false
        }).catch((error) => {
            this.initialRoute.routeStopGroups = this.routeStopGroups
            this.helperService.errorMessage(error);
            this.helperService.isLoading = false
        });
    }
}
