import { Driver } from '@models/driver.model';
import { Company } from '@models/company.model';
import { Truck } from '@models/truck.model';
import { Zone } from '@models/zone.model';
import { RouteStopGroup } from '@models/route-stop-group.model';
import { RouteItem } from '@models/route-item.model';
import { OrderStop } from '@models/order-stop.model';
import DirectionsResult = google.maps.DirectionsResult;
import { BaseModel } from '@models/base.model';
import { WaypointInfo } from './map.model';

export interface Route extends BaseModel {
    routeId?: number;
    companyId?: number;
    company?: Company;
    name?: string;
    driver?: Driver;
    truck?: Truck;
    status?: RouteStatusEnum;
    zone?: Zone;
    routeItems?: RouteItem[];
    routeStopGroups?: RouteStopGroup[];
    routeStops?: OrderStop[];
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    hidden?: boolean;
    directions?: DirectionsResult;
    millage?: number;
    duration?: number;
    avoidTolls?: boolean;
    directionsJson?: string;
    // read only
    nextStop?: string;
    nextStopData?: RouteNextStopData;
}
export interface Routes {
    route?: Route;
    previousStop?: string;
    nextStop?: string;
    nextStopData?: RouteNextStopData;
    totalStopsCount?: number;
    completedStopsCount?: number;
    vehicleFreeCapacity?: number;
    vehicleUsedCapacity?: number;
    waypoints?: WaypointInfo[];
    selected?: boolean;

}
export interface RouteNextStopData {
    address?: string;
    addressDetails?: string;
    date?: string | Date
    distance?: { text: string, value: number }
    duration?: { text: string, value: number }
    location?: { lat: number, lng: number }
    isDone?: boolean;
    orderStopId?: number;
    orderStopType?: string;
    priority?: string;
}

export enum NewRouteStep {
    zone,
    driver,
    truck
}

export enum RouteStatusEnum {
    ACTIVE = 'Active',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
}


