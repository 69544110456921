import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateRangeKeyEnum } from "@app/@shared/components/form";
import { DatatableComponent } from "@app/@shared/datatable";
import { AppService } from "@app/shared/services/app.service";
import { LogsService } from "@app/shared/services/logs.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import * as moment from 'moment';
import { debounceTime } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "app-application-logs",
    templateUrl: "./application-logs.component.html",
    styleUrls: ["./application-logs.component.scss"],
})
export class ApplicationLogsComponent implements OnInit, OnDestroy {
    title = "Application Logs";
    searchDate = {
        start: moment().subtract(7, 'day').toDate(),
        end: new Date(),
    }

    ranges: any = [
        {
            title: DateRangeKeyEnum.THIS_WEEK,
            value: [moment().startOf('week'), moment().endOf('week')]
        },
        {
            title: DateRangeKeyEnum.LAST_WEEK,
            value: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')]
        },
        {
            title: DateRangeKeyEnum.THIS_MONTH,
            value: [moment().startOf('month'), moment().endOf('month')]
        },
        {
            title: DateRangeKeyEnum.LAST_MONTH,
            value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        {
            title: DateRangeKeyEnum.THIS_YEAR,
            value: [moment().startOf('year'), moment().endOf('year')]
        },
        {
            title: DateRangeKeyEnum.LAST_YEAR,
            value: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        },
    ];

    @ViewChild("datatable") datatable: DatatableComponent;

    constructor(
        private logsService: LogsService,
        private appService: AppService,
    ) { }

    getData = (request?: any) => {
        request.DateRange = [this.searchDate.start?.toDateString() || new Date()?.toDateString(),this.searchDate.end?.toDateString() || new Date()?.toDateString()]
        this.logsService.cancelPendingRequestGetAll()
        return this.logsService.getAllWithCancelRequest(request)
    };

    ngOnInit() {
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable.setSearchValue(text);
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    dateRangeChange() {        
        this.datatable.refresh();
    }
}
