import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent } from "@app/@shared/datatable";
import { ActivityLogService } from "@app/shared/services/activity-log.service";
import { AppService } from "@app/shared/services/app.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "app-activity-log",
    templateUrl: "./activity-log.component.html",
    styleUrls: ["./activity-log.component.scss"],
})
export class ActivityLogComponent implements OnInit, OnDestroy {
    title = "Activity Logs";
    searchDate: Date = null;

    @ViewChild("datatable") datatable: DatatableComponent;

    constructor(
        private activityLogService: ActivityLogService,
        private appService: AppService,
    ) { }

    getData = (request?: any) => {
        if (this.searchDate) {
            request.Date = this.searchDate?.toDateString();
        }
        this.activityLogService.cancelPendingRequestGetAll()
        return this.activityLogService.getAllWithCancelRequest(request)
    };

    ngOnInit() {
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable.setSearchValue(text);
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    dateChanged(date) {
        this.searchDate = date;
        this.datatable.refresh();
    }
}
