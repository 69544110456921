import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { BillingRoutingModule } from './systems-routing.module';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { SystemsComponent } from './systems.component';
import { EnumFlagsDropDownSelectModule } from '@app/@shared/enum-flags-drop-down-select/enum-flags-drop-down-select.module';

@NgModule({
  declarations: [
    SystemsComponent
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SharedModule,
    EnumFlagsDropDownSelectModule
  ]
})
export class SystemsModule { }
