import { Customer } from '@models/customer.model';
import { AutoCloseTimeEnum } from './system.model';

export interface TruckMonthlyExpense {
    truckMonthlyExpenseId: number;
    name: string;
    price: number;
    type: TruckExpenseType;
}

export interface TenantCompanyList {
    companyId?: number;
    companyName?: string;
    maxRouteAddresses?: number;
}
export interface Company {
    cardknoxEndpoint?: string;
    cardknoxKey?: string;
    cardknoxSoftwareName?: string;
    cardknoxSoftwareVersion?: string;
    cardknoxVersion?: string;
    website?: string;
    city?: string;
    companyId?: number;
    companyName?: string;
    companySize?: number;
    companyStatus?: number;
    costPerMile?: number;
    // customers?: null
    defaultDriverWage?: number;
    defaultGasState?: string;
    defaultMarkupPercent?: number;
    email?: string;
    emailCC?: string;
    emailFromAccounting?: string;
    emailFromOrder?: string;
    fromEmail?: string;
    faxNumber?: string;
    fullAddress?: string;
    invoiceNotes?: string;
    invoices?: null;
    logoUrl?: string;
    maxFreeWaitingTime?: number;
    orderNumbersIndex?: number;
    payments?: null;
    phoneNumber?: string;
    registrationNumber?: string;
    tenantId?: string;
    state?: string;
    termsAndConditions?: string;
    driversApp?: string;
    transactionNumberIndex?: number;
    truckMilesPerMonth?: number;
    truckMonthlyExpenses?: TruckMonthlyExpense[];
    useNewPricing?: boolean;
    zip?: string;
    customerId?: number;
    openBalance?: number;
    truckAxleNumberForTollPricing?: number;
    invoiceDaily?: boolean;
    invoiceWeekly?: boolean;
    invoiceMonthly?: boolean;
    invoicePerOrder?: boolean;
    invoicePerLoad?: boolean;
    invoicePerShipment?: boolean;
    maxRouteAddresses?: number;
    invoiceCreationMode?: InvoiceCreationMode;
    allTabAutoClose?: AutoCloseTimeEnum;
    quoteTabAutoClose?: AutoCloseTimeEnum;
    orderTabAutoClose?: AutoCloseTimeEnum;
    dispatchTabAutoClose?: AutoCloseTimeEnum;
    customerTabAutoClose?: AutoCloseTimeEnum;
    invoiceTabAutoClose?: AutoCloseTimeEnum;
    driverTabAutoClose?: AutoCloseTimeEnum;
    outsourceTabAutoClose?: AutoCloseTimeEnum;
    weight?: string;
    distance?: string;
    size?: string;
}



export enum TruckExpenseType {
    Unspecified,
    Gas,
    Insurance,
    Maintenance
}

export enum InvoiceCreationMode {
    CreatedAndCompleted = 0,
    Completed = 1,
    Manual = 2,
}
