export enum AutoCloseTimeEnum {
    Never,
    FiveMin,
    TenMin,
    FifteenMin,
    ThirtyMin,
    OneHour,
    TwoHours,
    ThreeHours,
    SixHours,
    NineHours,
    TwelveHours,
    TwentyFourHours,
}

export const AutoCloseTimeMappings: Readonly<Record<AutoCloseTimeEnum, number | null>> = Object.freeze({
    [AutoCloseTimeEnum.Never]: null, // No auto-close
    [AutoCloseTimeEnum.FiveMin]: 5 * 60 * 1000,  // 5 minutes in ms
    [AutoCloseTimeEnum.TenMin]: 10 * 60 * 1000,  // 10 minutes in ms
    [AutoCloseTimeEnum.FifteenMin]: 15 * 60 * 1000, // 15 minutes in ms
    [AutoCloseTimeEnum.ThirtyMin]: 30 * 60 * 1000, // 30 minutes in ms
    [AutoCloseTimeEnum.OneHour]: 60 * 60 * 1000,   // 1 hour in ms
    [AutoCloseTimeEnum.TwoHours]: 2 * 60 * 60 * 1000,  // 2 hours in ms
    [AutoCloseTimeEnum.ThreeHours]: 3 * 60 * 60 * 1000,  // 3 hours in ms
    [AutoCloseTimeEnum.SixHours]: 6 * 60 * 60 * 1000, // 6 hours in ms
    [AutoCloseTimeEnum.NineHours]: 9 * 60 * 60 * 1000, // 9 hours in ms
    [AutoCloseTimeEnum.TwelveHours]: 12 * 60 * 60 * 1000, // 12 hours in ms
    [AutoCloseTimeEnum.TwentyFourHours]: 24 * 60 * 60 * 1000, // 24 hours in ms
});