import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsGlobalAdminGuard {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.authService.$user.pipe(
            map((user) => {
                const isGlobalAdmin = user?.roles?.includes('GLOBAL_ADMIN');
                if (!isGlobalAdmin) {
                    this.router.navigate(['/app/settings/404']);
                }
                return isGlobalAdmin;
            })
        );
    }
}
