

export type ViewEntityType = 'Order' | 'Customer' | 'CustomerMessage'


export interface ViewEntityStatistic {
  count?: number,
  type?: ViewedEntityTypeEnum;
  ids?: number[];
}

export interface CustomerMessageStatistic {
  id?: number,
  isViewed?:boolean;
  unviewedMessageIds?:number[]
}

export enum ViewedEntityTypeEnum {
  ORDER = 'Order',
  CUSTOMER = 'Customer',
  CUSTOMER_MESSAGE = 'CustomerMessage',
}

export type ViewEntityStatisticData  = {
  [x in ViewedEntityTypeEnum]: ViewEntityStatistic
}

export enum ViewedEntityTypeValueEnum {
  ORDER = 1,
  CUSTOMER = 2,
  CUSTOMER_MESSAGE = 3,
}