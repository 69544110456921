import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { splitPhoneNumberAndExtension } from '@app/@shared/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-phone-extension-input',
  templateUrl: './phone-extension-input.component.html',
  styleUrls: ['./phone-extension-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneExtensionInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PhoneExtensionInputComponent implements OnInit {

  @Input() disabled: boolean;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() phoneLabel: string = 'Phone';
  @Input() extensionLabel: string = 'Extension';
  @Input() options: any[] = [];
  @Input() phonePlaceholder = 'Phone Number';
  @Input() extensionPlaceholder = 'Extension';



  onChange = (value: string) => { };
  onTouched = () => { };

  @Output() onSelect = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();

  @Output() phoneChange = new EventEmitter<any>();
  @Input()
  public get phone() {
    return this._phoneNumber;
  }
  public set phone(value) {
    this._phoneNumber = value as any;
  } 

  @Output() extensionChange = new EventEmitter<any>();
  @Input()
  public get extension() {
    return this._extensionNumber;
  }
  public set extension(value) {
    this._extensionNumber = value;
  }

  private _extensionNumber: number;
  get extensionNumber(): number {
    return this._extensionNumber;
  }
  set extensionNumber(updateExtension: number) {
    this._extensionNumber = updateExtension;
    this.extensionChange.emit(updateExtension)
    this.handleValueChange()
  }

  private _phoneNumber: number;
  get phoneNumber(): number {
    return this._phoneNumber;
  }
  set phoneNumber(updatePhoneNumber: number) {
    this._phoneNumber = updatePhoneNumber;

    if (!this._phoneNumber) {
      this.extensionNumber = null;
    }

    this.phoneChange.emit(updatePhoneNumber)
    this.handleValueChange()
  }

  constructor() {

  }

  ngOnInit(): void {
  }

  handleValueChange() {
    let val = ''
    if (this._phoneNumber) {
      val += this._phoneNumber
    }
    if (this._phoneNumber && this._extensionNumber) {
      val += ` x ${this._extensionNumber}`
    }
    this.onChangeValue.emit(val);
    this.onChange(val);
    this.onTouched()
  }

  clearValue() {
    const value = null
    this.phoneNumber = value;
    this.extensionNumber = value;
    this.onChange(value);
    this.onChangeValue.emit(value);
  }

  writeValue(value: string) {
    const { phone, extension } = splitPhoneNumberAndExtension(value)
    if (phone || extension) {
      this._phoneNumber = phone as any || '';
      this._extensionNumber = extension as any || '';
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // This function restricts input to numeric values on keypress
  validateNumberInput(event: KeyboardEvent): void {
    const charCode = event.which ? event.which : event.keyCode;

    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  // This function validates and cleans non-numeric characters when pasting
  validateOnPaste(event: any, type: 'phone' | 'extension'): void {
    const inputValue = event.target.value;

    // Replace any non-numeric characters
    if (type === 'phone') {
      this.phoneNumber = inputValue.replace(/[^0-9]/g, '');
    }
    if (type === 'extension') {
      this.extensionNumber = inputValue.replace(/[^0-9]/g, '');
    }
  }
}
