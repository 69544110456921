import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  Navigation = [
    {
      header: "GENERAL",
      navLists: [
        {
          path: 'profile',
          title: 'Profile',
        },
        {
          path: 'users',
          title: 'Users',
        },
        {
          path: 'fleet',
          title: 'Fleet',
        },
        {
          path: 'locations',
          title: 'Locations',
        },
        {
          path: 'expenses',
          title: 'Expenses',
        },
      ]
    },
    {
      header: "SETUP",
      navLists: [
        {
          path: 'drivers-settings',
          title: 'Drivers Settings',
        },

        {
          path: 'billing',
          title: 'Billing',
        },
        {
          path: 'charge-types',
          title: 'Charge Types',
        },
        {
          path: 'pricing-models',
          title: 'Pricing Models',
        },
        // Now we use new Pricing Models instead of Shipment Types
        // {
        //   path: 'shipment-types',
        //   title: 'Shipment Types',
        // },
        {
          path: 'zones',
          title: 'Zones',
        },
        {
          path: 'integrations',
          title: 'Integrations',
        },
        {
          path: 'driver-app',
          title: 'Driver App',
        },
        {
          path: 'systems',
          title: 'Systems',
        },
      ]
    },
    {
      navLists: [
        {
          path: 'activity-logs',
          title: 'Activity Logs',
        },
        {
          path: 'terms-conditions',
          title: 'Terms & Conditions',
        },
        {
          path: 'version',
          title: 'Version',
        }
      ]
    }
  ]

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        const isGlobalAdmin = user?.roles?.includes("GLOBAL_ADMIN");
        if (isGlobalAdmin) {
          this.Navigation[2]?.navLists?.splice(2, 0, {
            path: 'application-logs',
            title: 'Application Logs',
          });
        }
      });
  }

}
