import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutesRoutingModule } from './routes-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { RoutesComponent } from './routes.component';
import { RoutesMapComponent } from './routes-map/routes-map.component';
import { RoutesTrimbleMapComponent } from './routes-trimble-map/routes-trimble-map.component';
import { AgmCoreModule } from '@agm/core';
import { DocumentViewerModule } from '@app/@shared/document-viewer/document-viewer.module';
import { ChatHistoryViewerModule } from '@app/@shared/chat-history-viewer/chat-history-viewer.module';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CopyDetailsModule } from '@app/@shared/copy-details/copy-details.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { RouteDetailsComponent } from './route-details/route-details.component';
import { AddEditRouteDialogComponent } from './add-edit-route-dialog/add-edit-route-dialog.component';
import { RouteDetailsBriefComponent } from './route-details-brief/route-details-brief.component';
import { RouteTrimbleMapComponent } from './route-trimble-map/route-trimble-map.component';
import { RouteHistoryComponent } from './route-history/route-history.component';
import { ZoneSelectModule } from '@app/@shared/zone-select/zone-select.module';
import { ConfirmStopItemDialogComponent } from './routes-status-block/confirm-stop-item-dialog/confirm-stop-item-dialog.component';
import { ConfirmStopDialogComponent } from './routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component';
import { AddUnassignedStopsComponent } from './routes-status-block/add-unassigned-stops/add-unassigned-stops.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RoutesStatusBlockComponent } from './routes-status-block/routes-status-block.component';
import { DriverSelectModule } from '@app/@shared/driver-select/driver-select.module';
import { TruckSelectModule } from '@app/@shared/truck-select/truck-select.module';
import { RouteMapModule } from '@app/@shared/route-map/route-map.module';
import { AssignTransferDialogComponent } from './route-details/assign-transfer-dialog/assign-transfer-dialog.component';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { PickupOrderDetailDialogComponent } from './routes-status-block/pickup-order-detail-dialog/pickup-order-detail-dialog.component';
import { PickupOrderDetailModule } from '../orders/components/pickup-order-detail/pickup-order-detail.module';
import { EditDeliveryDialogModule } from '../orders/components/edit-delivery-dialog/edit-delivery-dialog.module';
import { EditPickupDialogModule } from '../orders/components/edit-pickup-dialog/edit-pickup-dialog.module';
import { CopyDetailsToDriverModule } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.module';
import { RouteStopTransferDialogComponent } from './route-details/route-stop-transfer-dialog/route-stop-transfer-dialog.component';
import { EditOrderStopAddressDialogModule } from '../orders/components/edit-order-stop-address-dialog/edit-order-stop-address-dialog.module';
import { RouteUnassignStopIconComponent } from './route-unassign-stop-icon/route-unassign-stop-icon.component';
import { ChargeTypesModule } from '../settings/charge-types/charge-types.module';
import { EditRouteDriverComponent } from './routes-status-block/edit-route-driver/edit-route-driver.component';
import { OrderPriorityModule } from '@app/@shared/order-priority/order-priority.module';
import { NgxSmoothDnDModule } from '@app/@shared/ngx-smooth-dnd';
import { UnassignedTripsModule } from '@app/@shared/unassigned-trips/unassigned-trips.module';
import { TypeaheadSelectModule } from '@app/@shared/typeahead-select/typeahead-select.module';
import { PhoneExtensionInputModule } from '@app/@shared/phone-extension-input/phone-extension-input.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { DateTimeInputModule } from '@app/@shared/date-time-input/date-time-input.module';
import { RouteDetailsHorizontalComponent } from './route-details/componetnts/route-details-horizontal/route-details-horizontal.component';
import { RouteDetailsVerticalComponent } from './route-details/componetnts/route-details-vertical/route-details-vertical.component';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { WarningConfirmationDialogComponent } from './route-details/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { WarehouseSelectModule } from '@app/@shared/warehouse-select/warehouse-select.module';
import { WaitingTimeInputModule } from '@app/@shared/waiting-time-input/waiting-time-input.module';
import { PrintBolModule } from '@app/@shared/print-bol/print-bol.module';
import { AgmJsMarkerClustererModule } from '@app/@shared/angular-google-maps/js-marker-clusterer';
import { ConfirmStopItemsTableComponent } from './routes-status-block/confirm-stop-dialog/confirm-stop-items-table/confirm-stop-items-table.component';


@NgModule({
  declarations: [
    RoutesComponent,
    RoutesMapComponent,
    RoutesTrimbleMapComponent,
    RoutesStatusBlockComponent,
    RouteDetailsComponent,
    AddEditRouteDialogComponent,
    RouteDetailsBriefComponent,
    RouteTrimbleMapComponent,
    RouteHistoryComponent,
    ConfirmStopItemDialogComponent,
    ConfirmStopDialogComponent,
    ConfirmStopItemsTableComponent,
    AddUnassignedStopsComponent,
    AssignTransferDialogComponent,
    PickupOrderDetailDialogComponent,
    RouteStopTransferDialogComponent,
    RouteUnassignStopIconComponent,
    EditRouteDriverComponent,
    RouteDetailsHorizontalComponent,
    RouteDetailsVerticalComponent,
    WarningConfirmationDialogComponent
  ],
  exports: [
    RoutesStatusBlockComponent,
    AddUnassignedStopsComponent,
    AssignTransferDialogComponent
  ],
  imports: [
    CommonModule,
    RoutesRoutingModule,
    SharedModule,
    AgmCoreModule,
    AgmSnazzyInfoWindowModule,
    DocumentViewerModule,
    ChatHistoryViewerModule,
    NgbModule,
    CopyDetailsModule,
    CopyDetailsToDriverModule,
    DialogsModule,
    RatingModule,
    AttachmentsModule,
    RatingModule,
    NgbCollapseModule,
    ZoneSelectModule,
    DriverSelectModule,
    TruckSelectModule,
    NgxMaterialTimepickerModule,
    RouteMapModule,
    FormValidateModule,
    PickupOrderDetailModule,
    EditDeliveryDialogModule,
    EditPickupDialogModule,
    EditOrderStopAddressDialogModule,
    ChargeTypesModule,
    OrderPriorityModule,
    NgxSmoothDnDModule,
    UnassignedTripsModule,
    TypeaheadSelectModule,
    PhoneExtensionInputModule,
    DragDropModule,
    CdkScrollableModule,
    DateTimeInputModule,
    FileUploadModule,
    WarehouseSelectModule,
    WaitingTimeInputModule,
    PrintBolModule,
    AgmJsMarkerClustererModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RoutesModule { }
