import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { Customer } from '@app/models/customer.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { HelperService } from '@app/shared/services/helper.service';
import { CustomerMessage, CustomerMessageTypeEnum } from '@app/models/customer-message.model';
import { CustomerMessagesService } from '@app/shared/services/customer-message.service';
import { ViewEntityService } from '@app/shared/services/view-entity.service';
import { get } from 'lodash';
import { ViewedEntityTypeValueEnum } from '@app/models/view-entity.modal';

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit {
  @ViewChild('messageContainer') private messageContainer: ElementRef;
  private _customer: Customer;
  notes: CustomerMessage[] = [];
  states = stateOptions;
  newMessage: string = '';

  ImageUploadTypeEnum = ImageUploadTypeEnum;

  @Input()
  public get customer(): Customer {
    return this._customer;
  }
  public set customer(value: Customer) {
    this._customer = value;
  }

  @Output() customerChange = new EventEmitter();

  constructor(
    private helperService: HelperService,
    private customerMessagesService: CustomerMessagesService,
    private viewEntityService: ViewEntityService,
  ) {
  }

  ngOnInit(): void {
    this.loadCustomerMessages();
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  private async loadCustomerMessages(): Promise<void> {
    if (this.customer?.customerId) {
      try {
        this.notes = await this.customerMessagesService.getByCustomerId(this.customer.customerId);
      } catch (error) {
        console.error('Error fetching customer messages', error);
      }

      const statistic = get(this.viewEntityService.dataStore.customerStatics, this.customer?.customerId, null)
      if (statistic && statistic?.unviewedMessageIds?.length > 0) {
        this.viewEntityService.markAsViewByIds(statistic.unviewedMessageIds, ViewedEntityTypeValueEnum.CUSTOMER_MESSAGE).then(() => {
          statistic.unviewedMessageIds = []
          this.viewEntityService.setCustomerStatics(statistic, this.customer?.customerId)
        }).catch((error) => {
          this.helperService.errorMessage(error)
        })
      }
    }
  }

  async sendMessage(): Promise<void> {
    if (this.newMessage.trim() !== '') {
      try {
        let formData = new FormData();
        formData.append('customerId', this.customer?.customerId.toString());
        formData.append('text', this.newMessage);

        await this.customerMessagesService.sendMessage(formData);
  
        this.notes.push({
          text: this.newMessage,
          type: CustomerMessageTypeEnum.Outcoming
        });
        this.newMessage = '';
        this.scrollToBottom();
      } catch (error) {
        this.helperService.errorMessage(error);
      }
    }
  }

  private scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch(err) {
      console.error('Error scrolling to bottom:', err);
    }
  }
}
