import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { ContactCreationType, CustomerContact } from '@app/models/customer-contact.model';
import { Customer } from '@app/models/customer.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { CustomerContactService } from '@app/shared/services/customer-contact.service';
import { HelperService } from '@app/shared/services/helper.service';
import { find, get, orderBy, split, startsWith } from 'lodash';
import { ValidationError, array, object, string } from 'yup';
import { AddressService } from '@app/shared/services/address.service';
import { Country } from '@app/models/address.model';

export interface CustomerContactItem extends CustomerContact {
    isView?: boolean,
    isEdit?: boolean,
    isDelete?: boolean,
}

@Component({
    selector: "app-contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
    private _customer: Customer;
    contacts: CustomerContactItem[] = [{
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
    }];
    states = stateOptions;
    ContactCreationType = ContactCreationType;

    validationSchema = object().shape({
        contacts: array().of(
            object().shape({
                email: string().label('Email').nullable().max(256).email(),
                firstName: string().label('First Name').nullable().max(256),
                lastName: string().label('Last Name').nullable().max(256),
                phone: string().label('Contact Number').when('email', {
                    is: (email) => !email,
                    then: (schema) => schema.required('Either contact Number or email is required').max(256),
                    otherwise: (schema) => schema.nullable().max(256)
                }),
            })
        ).test('unique-contacts', 'Contacts must be unique', function (arr: any[]) {
            const path = this.path;
            let errors = [];
            const seen = new Set();

            arr.forEach((contact, index) => {
                if (!contact.email && !contact.phone) {
                    errors.push(this.createError({ message: `Either contact Number or email is required`, path: `${path}[0].phone` }));
                }
                const key = `${contact.firstName || ''} ${contact.lastName || ''} ${contact.email || ''} ${contact.phone || ''}`.trim();
                if (seen.has(key)) {
                    errors.push(this.createError({ message: `Contact with provided Email, Phone and Name already exists`, path: `${path}[${index}].imageUrl` }));
                }
                seen.add(key);
            });

            if (errors?.length > 0) {
                return new ValidationError(errors);
            }

            return true

        }),
    });

    ImageUploadTypeEnum = ImageUploadTypeEnum;
    countries: Country[];

    @Input()
    public get customer(): Customer {
        return this._customer;
    }
    public set customer(value: Customer) {
        this._customer = value;
        let contacts = value?.customerContacts || [];

        let duplicatedContact = contacts.find(x => {
            let contactName = (x.firstName + (!!x.lastName ? ` ${x.lastName}` : ''));
            return contactName == value.customerName && x.phone == value.phone && x.email == value.firstEmail
        });

        if (duplicatedContact)
            contacts = contacts.filter(x => x.customerContactId != duplicatedContact.customerContactId);

        this.contacts = contacts;
    }

    @Output() customerChange = new EventEmitter();

    constructor(
        private customerContactService: CustomerContactService,
        private addressService: AddressService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit(): void {
        this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
			this.countries = [];
			this.helperService.errorMessage(error)
		})
     }

    cancelViewContact(index) {
        const contact = this.contacts[index];
        if (contact && contact.customerContactId) {
            contact.isView = false;
            contact.isEdit = false;
            const oldContact = find(this.customer.customerContacts, { customerContactId: contact.customerContactId })
            this.contacts.splice(index, 1, oldContact);
        } else {
            this.contacts.splice(index, 1);
        }
    }

    viewContact(item) {
        item.isView = !item.isView;
    }

    private setContactName(contact, customerName, number = 1) {
        contact.firstName = `Contact ${number}`;
        contact.lastName = customerName.substring(0, 20);
        return contact
    }

    async saveContact(isValid, index) {
        if (!get(isValid?.errors?.contacts, index, false)) {
            let contact = await this.contacts[index];
            if (this.customer.customerName && !contact?.firstName && !contact?.lastName) {
                const oldContacts = this.customer.customerContacts?.filter(({ customerContactId, firstName }) => !!customerContactId && startsWith(firstName, 'Contact'))
                if (oldContacts?.length > 0) {
                    const lastContact = orderBy(oldContacts, ['firstName'], 'desc')[0]
                    const newFirstName = split(lastContact?.firstName, ' ')
                    if (newFirstName?.length > 1) {
                        const number = Number(newFirstName[1]) ? Number(newFirstName[1]) + 1 : 1
                        contact = this.setContactName(contact, this.customer.customerName, number)
                    }
                    else {
                        contact = this.setContactName(contact, this.customer.customerName)
                    }
                }
                else {
                    contact = this.setContactName(contact, this.customer.customerName)
                }
            }
            if (contact && contact?.customerContactId) {
                this.customerContactService.update(contact.customerContactId, contact)
                    .then(async (data) => {
                        this.contacts[index].isView = false;
                        this.contacts.splice(index, 1, data);
                        this.customer.customerContacts = this.contacts
                        this.customerChange.emit(this.customer)
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    })
            } else {
                contact.customerId = this.customer?.customerId
                this.customerContactService.create(contact)
                    .then(async (data) => {
                        this.contacts[index].isView = false;
                        this.contacts.splice(index, 1, data);
                        this.customer.customerContacts = this.contacts
                        this.customerChange.emit(this.customer)
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    })
            }
        }
    }

    deleteContact(index) {
        const contact = this.contacts[index]
        if (contact) {
            this.customerContactService.delete(contact.customerContactId)
                .then(() => {
                    this.contacts.splice(index, 1);
                    this.customer.customerContacts = this.contacts
                    this.customerChange.emit(this.customer)
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                })
        } else {
            this.contacts.splice(index, 1);
        }

    }

    /**
     * Set primary contact as default
     * 
     * @param event 
     */
    async setPrimaryContact(index) {
        try {
            const contact = this.contacts[index];
            await this.customerContactService.update(contact?.customerContactId, {
                ...contact,
                isPrimary: true
            });
            this.contacts.forEach((res) => {
                res.isPrimary = res.customerContactId === contact.customerContactId;
            });
            this.customer.customerContacts = this.contacts
            this.customerChange.emit(this.customer)
        } catch (error) {
            console.log('Error while set customer contact as default primary contact', error);
            this.helperService.errorMessage(error);
        }
    }

}


