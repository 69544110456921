import { Component, Input, OnInit, } from '@angular/core';
import { ChargeType } from '@app/models/charge-type.model';
import { EntityTypes } from '@app/models/entity-type.model';
import { RouteItemStop, stopStatusTypes } from '@app/models/route-item-stop.model';
import { RouteItemStatus } from '@app/models/route-item.model';
import { ApiService } from '@app/services/api.service';
import { RouteService } from '@app/services/route.service';
import { HelperService } from '@app/shared/services/helper.service';
import { Route } from '@models/route.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmStopDialogComponent } from '../routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { getStopActionLabel, getStopTypeLabel, getStopTypeLabelColor } from '@app/@shared/utils';

@Component({
    selector: 'app-route-history',
    templateUrl: './route-history.component.html',
    styleUrls: ['./route-history.component.scss']
})

export class RouteHistoryComponent implements OnInit {
    @Input() routes: Route[];
    @Input() route: Route;
    chargeTypes: ChargeType[];
    EntityTypes = EntityTypes;
    getStopTypeLabelColor = getStopTypeLabelColor;
    getStopActionLabel = getStopActionLabel;
    getStopTypeLabel = getStopTypeLabel;
    stopStatusTypes = stopStatusTypes;

    constructor(
        private api: ApiService,
        public chargeTypeService: ChargeTypeService,
        private modalService: NgbModal,
        public routeService: RouteService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.chargeTypeService.perStopChargeTypes$
            .subscribe(chargeTypes => this.chargeTypes = chargeTypes);
    }

    updateRouteStopStatus(stop: RouteItemStop, newStatus: RouteItemStatus) {
        if (newStatus === RouteItemStatus.Done) {
            const confirmStop = stop;
            if (!confirmStop.waitingTime) {
                confirmStop.waitingTime = '';
            }
            if (!confirmStop.arrivalDate) {
                confirmStop.arrivalDate = new Date();
            }
            confirmStop.routeItems.forEach(ri => {
                ri.pickQuantity = ri.quantity;
            });
            confirmStop.charges = this.chargeTypes.map(x => Object.assign({}, x));
            const activeModal = this.modalService.open(ConfirmStopDialogComponent, {
                scrollable: true,
                size: "lg",
            });
            activeModal.componentInstance.title = `Confirm ${getStopTypeLabel(confirmStop)}`;
            activeModal.componentInstance.routeId = this.route.routeId;
            activeModal.componentInstance.confirmStop = confirmStop;
            activeModal.componentInstance.confirmStopNewStatus = newStatus;
            activeModal.result.then(
                (result) => {
                    if (result) {
                        console.log(result);
                    }
                },
                () => { }
            );
        } else {
            this.api.UpdateRouteStopStatus(this.route.routeId, stop, newStatus, success => {
                this.helperService.successMessage("Route stop status successfully updated.");
                this.refreshRoutes();
            }, error => {
                this.helperService.errorMessage(error);
            });
        }
    }

    refreshRoutes() {
        this.routeService.reloadRoutes();
    }


}
