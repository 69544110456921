import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Routes, Route } from '@app/models/route.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RouteBoxGroup } from '@app/models/route-box-group.model';
import { Truck } from '@app/models/truck.model';
import { RouteBox } from '@app/models/route-box.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { RouteItemDisplayStatus, RouteItemStop } from '@app/models/route-item-stop.model';
import { TransferStop } from '@app/models/transfer-stop.model';
import { takeUntil, tap } from 'rxjs/operators';
import { map } from 'lodash';
import { HelperService } from './helper.service';
import { CompanyService } from './company.service';
import { AttemptDialogResult } from '@app/@shared/unassigned-trips/attempt-dialog/attempt-dialog.component';

interface OpenRoutesDataStore {

    [x: string]: { isOpen: boolean, isHistory?: boolean }
}
export interface MarkersDisplay {
    showTrucks?: boolean
    showRoutes?: boolean
    showUnassigned?: boolean
    showPickups?: boolean
    showDropoffs?: boolean
}
@Injectable({
    providedIn: 'root'
})

export class RoutesService {
    dataStore: {
        routeViewType?: 'grid' | 'list',
        reloadUnassigned?: boolean,
        reloadRoutes?: boolean,
        allRoutes?: Routes[],
        unassignedStops?: RouteBoxGroup[],
        unassignedStopsGroups?: RouteBoxUIGroup[],
        currentRoute?: Route,
        currentRouteStop?: RouteItemStop,
        currentTruck?: Truck,
        currentRouteBox?: RouteBoxUIGroup,
        assignStopsToRoute?: {
            routeId: number,
            stops: RouteBoxUIGroup[]
        },
        reloadRoute: {
            routeId: number
        },
        openedRoutes: OpenRoutesDataStore
        mergedRouteBlocks: { [x: string]: boolean }
        markersDisplay: MarkersDisplay
    }
    private basePath = 'Routes'
    private _routeViewType: BehaviorSubject<'grid' | 'list'>;
    private _reloadUnassigned: BehaviorSubject<any>;
    private _reloadRoutes: BehaviorSubject<any>;
    private _allRoutes: BehaviorSubject<any>;
    private _unassignedStops: BehaviorSubject<RouteBoxGroup[]>;
    private _unassignedStopsGroups: BehaviorSubject<RouteBoxUIGroup[]>;
    private _currentRoute: BehaviorSubject<any>;
    private _currentRouteStop: BehaviorSubject<RouteItemStop>;
    private _currentTruck: BehaviorSubject<any>;
    private _currentRouteBox: BehaviorSubject<any>;
    private _assignStopsToRoute: BehaviorSubject<any>;
    private cancelRequestUnassignedRoutes$ = new Subject<void>();
    private cancelRequestGetAll$ = new Subject<void>();
    private _reloadRoute: BehaviorSubject<any>;
    private _openedRoutes: BehaviorSubject<OpenRoutesDataStore>;
    private _mergedRouteBlocks: BehaviorSubject<{ [x: string]: boolean }>;
    private _markersDisplay: BehaviorSubject<MarkersDisplay>;
    assignOrderStopToRoute: BehaviorSubject<boolean | string | number>;

    constructor(
        protected http: HttpClient,
        private companyService: CompanyService,
        protected helperService: HelperService
    ) {
        const viewType:any = localStorage.getItem('route.viewType')
        
        this.dataStore = {
            routeViewType: viewType || 'grid',
            reloadUnassigned: false,
            reloadRoutes: false,
            allRoutes: null,
            unassignedStops: null,
            unassignedStopsGroups: [],
            currentRoute: null,
            currentRouteStop: null,
            currentTruck: null,
            currentRouteBox: null,
            assignStopsToRoute: {
                routeId: null,
                stops: null
            },
            reloadRoute: {
                routeId: null
            },
            openedRoutes: {},
            mergedRouteBlocks: {},
            markersDisplay: {
                showTrucks: true,
                showRoutes: true,
                showUnassigned: false,
                showPickups: false,
                showDropoffs: false,
            }
        }
        this._routeViewType = new BehaviorSubject(this.dataStore.routeViewType);
        this._reloadUnassigned = new BehaviorSubject(this.dataStore.reloadUnassigned);
        this._reloadRoutes = new BehaviorSubject(this.dataStore.reloadRoutes);
        this._allRoutes = new BehaviorSubject(this.dataStore.allRoutes);
        this._unassignedStops = new BehaviorSubject(this.dataStore.unassignedStops);
        this._unassignedStopsGroups = new BehaviorSubject(this.dataStore.unassignedStopsGroups);
        this._currentRoute = new BehaviorSubject(this.dataStore.currentRoute);
        this._currentRouteStop = new BehaviorSubject(this.dataStore.currentRouteStop);
        this._currentTruck = new BehaviorSubject(this.dataStore.currentTruck);
        this._currentRouteBox = new BehaviorSubject(this.dataStore.currentRouteBox);
        this._assignStopsToRoute = new BehaviorSubject(this.dataStore.assignStopsToRoute);
        this._reloadRoute = new BehaviorSubject(this.dataStore.reloadRoute);
        this._openedRoutes = new BehaviorSubject(this.dataStore.openedRoutes);
        this._mergedRouteBlocks = new BehaviorSubject(this.dataStore.mergedRouteBlocks);
        this._markersDisplay = new BehaviorSubject(this.dataStore.markersDisplay);
        this.assignOrderStopToRoute = new BehaviorSubject(false)
    }

    public get assignOrderStopToRoute$() {
        return this.assignOrderStopToRoute.asObservable();
    };

    public get $routeViewType(): Observable<'grid' | 'list'> {
        return this._routeViewType.asObservable();
    }

    public set routeViewType(value: 'grid' | 'list') {
        this.dataStore.routeViewType = value;
        localStorage.setItem('route.viewType', value)
        this._routeViewType.next(value);
    }

    public get $allRoutes(): Observable<Routes[]> {
        return this._allRoutes.asObservable();
    }

    public set allRoutes(value: Routes[]) {
        this.dataStore.allRoutes = value;
        this._allRoutes.next(value);
    }

    public get $reloadUnassigned(): Observable<boolean> {
        return this._reloadUnassigned.asObservable();
    }

    public set reloadUnassigned(value: boolean) {
        this.dataStore.reloadUnassigned = value;
        this._reloadUnassigned.next(value);
    }

    public get $reloadRoutes(): Observable<boolean> {
        return this._reloadRoutes.asObservable();
    }

    public set reloadRoutes(value: boolean) {
        this.dataStore.reloadRoutes = value;
        this._reloadRoutes.next(value);
    }


    get unassignedStopsGroups(): RouteBoxUIGroup[] {
        return this.dataStore.unassignedStopsGroups;
    }
    get $unassignedStopsGroups(): Observable<RouteBoxUIGroup[]> {
        return this._unassignedStopsGroups.asObservable();
    };


    get unassignedStops(): RouteBoxGroup[] {
        return this.dataStore.unassignedStops;
    }

    set unassignedStops(value: RouteBoxGroup[]) {
        this.dataStore.unassignedStops = value
        this.dataStore.unassignedStopsGroups = RouteBoxUIGroup.createGroups(value);
        this._unassignedStops.next(this.dataStore.unassignedStops);
        this._unassignedStopsGroups.next(this.dataStore.unassignedStopsGroups);
    }
    get $unassignedStops(): Observable<RouteBoxGroup[]> {
        return this._unassignedStops.asObservable();
    };


    // public get $unassignedStops(): Observable<RouteBoxGroup[]> {
    //     return this._unassignedStops.asObservable();
    // }
    // public get $unassignedStops(): Observable<RouteBoxGroup[]> {
    //     return this._unassignedStops.asObservable();
    // }
    // public get $unassignedStops(): Observable<RouteBoxGroup[]> {
    //     return this._unassignedStops.asObservable();
    // }

    public get $currentRoute(): Observable<Route> {
        return this._currentRoute.asObservable();
    }

    public set currentRoute(value: Route) {
        this.dataStore.currentRoute = value;
        this._currentRoute.next(value);
    }

    public get $currentRouteStop(): Observable<RouteItemStop> {
        return this._currentRouteStop.asObservable();
    }

    public set currentRouteStop(value: RouteItemStop) {
        this.dataStore.currentRouteStop = value;
        this._currentRouteStop.next(value);
    }

    public get $currentTruck(): Observable<Truck> {
        return this._currentTruck.asObservable();
    }

    public set currentTruck(value: Truck) {
        this.dataStore.currentTruck = value;
        this._currentTruck.next(value);
    }

    public get $currentRouteBox(): Observable<RouteBoxUIGroup> {
        return this._currentRouteBox.asObservable();
    }

    public set currentRouteBox(value: RouteBoxUIGroup) {
        this.dataStore.currentRouteBox = value;
        this._currentRouteBox.next(value);
    }

    public get $assignStopsToRoute(): Observable<{ routeId: number, stops: RouteBoxUIGroup[] }> {
        return this._assignStopsToRoute.asObservable();
    }

    public set assignStopsToRoute(value: any) {
        this.dataStore.assignStopsToRoute.routeId = value.routeId;
        this.dataStore.assignStopsToRoute.stops = value.stop;
        this._assignStopsToRoute.next(value);
    }

    public get $reloadRoute(): Observable<{ routeId: number }> {
        return this._reloadRoute.asObservable();
    }

    public set reloadRoute(value: any) {
        this.dataStore.reloadRoute.routeId = value.routeId;
        this._reloadRoute.next(value);
    }


    get $openedRoutes(): Observable<OpenRoutesDataStore> {
        return this._openedRoutes.asObservable();
    };
    get $mergedRouteBlocks(): Observable<{ [x: string]: boolean }> {
        return this._mergedRouteBlocks.asObservable();
    };
    get $markersDisplay(): Observable<MarkersDisplay> {
        return this._markersDisplay.asObservable();
    };

    toggleRoute(key: string | number, isOpen: boolean, isHistory = false) {
        this.dataStore.openedRoutes[`${key}`] = {
            isOpen: isOpen,
            isHistory,
        }
        this._openedRoutes.next(this.dataStore.openedRoutes);
    }
    toggleMergedRouteBlock(key: string | number) {
        this.dataStore.mergedRouteBlocks[`${key}`] = !this.dataStore.mergedRouteBlocks[`${key}`]
        this._mergedRouteBlocks.next(this.dataStore.mergedRouteBlocks);
    }
    resetToggleRoutes() {
        this.dataStore.openedRoutes = {}
        this._openedRoutes.next(this.dataStore.openedRoutes);
    }

    toggleMarkersDisplay(data: MarkersDisplay) {
        const markerDisplay = { ...this.dataStore.markersDisplay, ...data }
        this.dataStore.markersDisplay = markerDisplay
        this._markersDisplay.next(this.dataStore.markersDisplay);
    }

    getRouteBox(stop: RouteBoxUIGroup): RouteBox {
        if (stop?.uniqPickupRouteBoxes?.length === 1) {
            return stop?.uniqPickupRouteBoxes[0];
        } else if (stop?.uniqDropOffRouteBoxes?.length === 1) {
            return stop?.uniqDropOffRouteBoxes[0];
        }
        return null;
    }


    getOrderRouteSnapshot(request) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/OrderRouteSnapshot`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    get(id: string | number, request = {}) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/${id}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    getAll(request?, loadMoreRoutes?: boolean) {
        return new Promise<ApiPaginationData<any>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<any>>(`${this.basePath}/Routes`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetAll$)
                )
                .subscribe(
                    ({ data }: any) => {
                        this.allRoutes = (request.page > 1 && loadMoreRoutes) ? this.dataStore.allRoutes.concat(data.list) : data.list;
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestGetAll() {
        // Call this method to cancel pending requests
        this.cancelRequestGetAll$.next();
    }

    create(request) {
        return this.http.post<ApiResponse<Route>>(`${this.basePath}`, request).toPromise();
    }

    update(id: string | number, request) {
        return this.http.put<ApiResponse<Route>>(`${this.basePath}/${id}`, request).toPromise();
    }

    delete(id) {
        return this.http.delete(`${this.basePath}/${id}`).toPromise();
    }

    createTransfer(request) {
        return this.http.post<ApiResponse<Route>>(`${this.basePath}/NewTransfer`, request).toPromise();
    }

    getTransferOptions(request) {
        return this.http.post<ApiResponse<TransferStop>>(`${this.basePath}/TransferOptions`, request).toPromise();
    }

    updateRouteStops(id: string | number, request, validateIsAssigned = false) {
        return this.http.put<ApiResponse<Route>>(`${this.basePath}/${id}/stops?validateIsAssigned=${validateIsAssigned}`, request).toPromise();
    }

    changeOrderRoute(request) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/ChangeOrderRoute`, request).toPromise();
    }

    updateRouteStopStatus(id: string | number, request, newStatus, getUpdateStatus = false) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${id}/routeItems/updateStatus/${newStatus}?getUpdateStatus=${getUpdateStatus}`, request).toPromise();
    }

    getRouteItemGroups(request?) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}/RouteItemGroups`, { params: toParams(request) })
            .toPromise()
            .then((({ data }) => data));
    }

    getUnassignedRoutes(request?, reloadUnassignedStops = true) {
        return new Promise<ApiPaginationResponse<any>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<any>>(`${this.basePath}/UnassignedRoutes`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestUnassignedRoutes$)
                )
                .subscribe(
                    ({ data }: any) => {
                        if (reloadUnassignedStops) {
                            this.unassignedStops = data.list
                        }
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestUnassignedRoutes() {
        // Call this method to cancel pending requests
        this.cancelRequestUnassignedRoutes$.next();
    }

    processNewPickupStop(stop, items, selectedRoute: Route) {
        // find group to add this stop to

        let group = selectedRoute?.routeStopGroups?.find(x => x.date === stop.date);
        if (!group) {
            group = {
                date: stop?.date,
                routeItemStops: []
            }
            selectedRoute.routeStopGroups.push(group);
        }

        // create route item group (its used to group by order)
        const routeItemStop: RouteItemStop = {
            routeItems: [],
            // for front end (until refresh)
            type: stop.stopType,
            displayStatus: RouteItemDisplayStatus.OnTime,
            toPickUp: stop.stopType === 'Pickup',
            dateType: stop.dateType,
            date: stop.date,
            timeType: stop.timeType,
            time: stop.time,
            address: {
                fullAddress: stop.fullAddress
            }
        };

        // add all selected items
        items.forEach(item => {
            routeItemStop.routeItems.push({
                orderItemStop: {
                    orderItemStopId: (stop.stopType === 'Pickup' ? item.routePickupOrderItemStopId : item.routeDeliveryOrderItemStopId)
                },
                date: stop.date,
                time: stop.time,
                quantity: (stop.stopType === 'Pickup' ? item.pickQuantity : item.pickedQuantity),
                orderItem: item
            });
        });
        group.routeItemStops.push(routeItemStop);
    }

    switchAvoidTolls(id: number | number, avoidTolls: Boolean) {
        return this.http.get<ApiResponse<Route>>(`${this.basePath}/AvoidTolls?routeId=${id}&avoidTolls=${avoidTolls}`).toPromise();
    }

    closeAllItemsRoutes(routeId: number) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${routeId}/RouteItems/CloseAll`, {}).toPromise();
    }

    updateRouteStatus(routeId: number, updateStatus: string) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${routeId}/updateStatus/${updateStatus}`, {}).toPromise();
    }

    moveToUnassigned(stopGroup: RouteBoxGroup, attempt: AttemptDialogResult) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/MoveToUnassigned`, { attempt, stopGroup }).toPromise();
    }
    
    moveOrderOnRouteToUnassigned(routeId: number, orderId: number) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/MoveOrderOnRouteToUnassigned?routeId=${routeId}&orderId=${orderId}`, {}).toPromise();
    }

    // Function to reset the state to initial values
    resetState() {
        this.routeViewType = 'grid'
        this.unassignedStops = []
        this.currentRoute= null
        this.currentRouteBox= null
        this.currentTruck = null
        this.assignStopsToRoute = { routeId: null, stops: [] }

        this.dataStore.openedRoutes = {}
        this._openedRoutes.next({});

        this.dataStore.mergedRouteBlocks = {}
        this._mergedRouteBlocks.next({});

        localStorage.removeItem('route.viewType');
    }
}
