// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host select {
  width: -moz-fit-content;
  width: fit-content;
}
:host select option:disabled {
  color: #ccc;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/expenses/expenses-date-selector/expenses-date-selector.component.scss"],"names":[],"mappings":"AAGI;EACI,uBAAA;EAAA,kBAAA;AAFR;AAIY;EACI,WAAA;EACA,kBAAA;AAFhB","sourcesContent":["@import \"var\";\n\n:host {\n    select {\n        width: fit-content;\n        option {\n            &:disabled {\n                color: #ccc;\n                font-style: italic;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
