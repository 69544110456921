import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationLogsRoutingModule } from './application-logs-routing.module';
import { DatatableModule } from '@app/@shared/datatable';
import { SharedModule } from '@app/shared/shared.module';
import { ApplicationLogsComponent } from './application-logs.component';
import { DateRangePickerModule } from '@app/@shared/components/form';


@NgModule({
  declarations: [
    ApplicationLogsComponent
  ],
  imports: [
    CommonModule,
    ApplicationLogsRoutingModule,
    DatatableModule,
    SharedModule,
    DateRangePickerModule
  ]
})
export class ApplicationLogsModule { }
