import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationLogsComponent } from './application-logs.component';

const routes: Routes = [{ path: '', component: ApplicationLogsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationLogsRoutingModule { }
