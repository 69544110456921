import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent, ConfirmData } from './confirm/confirm.component';
import { PromptComponent, PromptData } from './prompt/prompt.component';
import { CustomComponent, CustomData } from './custom/custom.component';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private modalService: NgbModal
  ) { }

  open(component: any, data: any = {}, options: NgbModalOptions = {}) {
    const modalRef = this.modalService.open(component, options);
    modalRef.componentInstance.data = data;

    return modalRef;
  }

  custom(data: CustomData = {}, options: NgbModalOptions = {}) {
    const modalRef = this.modalService.open(CustomComponent, options);
    modalRef.componentInstance.data = data;
  }

  confirm(data: ConfirmData = {}, options: NgbModalOptions = {}) {
    return new Promise((resolve, reject) => {
      const config: NgbModalOptions = { ...options };
      const modalRef = this.modalService.open(ConfirmComponent, config);

      modalRef.componentInstance.data = data;
      modalRef.result.then(
        (res) => {
          const oldData = cloneDeep(data);
          if (oldData?.callback && !oldData.preventOldDataCallback) {
            oldData?.callback(res);
          }
          if (res) {
            resolve(modalRef);
          } else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    })
  }

  prompt(data: PromptData = {}, options: NgbModalOptions = {}) {
    const modalRef = this.modalService.open(PromptComponent, options);
    modalRef.componentInstance.data = data;

    return modalRef;
  }

  close(): void {
    this.modalService.dismissAll();
  }
}
