import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "getAppointmentColorAndTooltip",
    pure: true,
})
export class GetAppointmentColorAndTooltipPipe implements PipeTransform {
    transform(date: Date, text: string): { class: string; tooltip: string } {
        const formattedDate = moment(date);
        const today = moment().startOf("day");

        if (formattedDate.isSame(today, "day")) {
            return { class: "text-success", tooltip: `For ${text}` };
        } else if (formattedDate.isBefore(today, "day")) {
            return { class: "text-danger", tooltip: `Late ${text}` };
        } else if (formattedDate.isAfter(today, "day")) {
            return { class: "text-warning", tooltip: `Upcoming ${text}` };
        }

        // Default case if none of the above matches
        return { class: "", tooltip: "" };
    }
}
