import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '@app/shared/services/driver.service';
import { HelperService } from '@app/shared/services/helper.service';
import { Driver, DriverStatusEnum } from '@app/models/driver.model';
import { DatatableComponent } from '@app/@shared/datatable';
import { flatMap, map, omitBy } from 'lodash';
import { AppService } from '@app/shared/services/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { AddressService } from '@app/shared/services/address.service';

@UntilDestroy()
@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss']
})
export class DriverListComponent implements OnInit, OnDestroy {

  DriverStatusEnum = DriverStatusEnum;
  selectedDriverIds: any;
  search: any = {
    SearchTerm: "",
    Page: 1,
    ItemsPerPage: 25,
    DriverStatus: [DriverStatusEnum.ACTIVE],
    CompanyTypes: [],
    CountryIds: [],
    Countries: [],
  };
  title = 'Drivers';
  drivers: Driver[];
  totalCount: number = 0;
  SortDirection: 'desc' | 'asc' = "desc";
  deletedDrivers: boolean = false;
  statusOptions = [
    { name: 'Active', value: DriverStatusEnum.ACTIVE, selected: false },
    { name: 'Disabled', value: DriverStatusEnum.INACTIVE, selected: false }
  ];
  userRoles: any[];
  @ViewChild('datatable') datatable: DatatableComponent;


  @Input() listTabs: ListTabsComponent;
  countryOptions: any;

  get isAdmin(): boolean {
    return this.userRoles.includes("COMPANY_ADMIN");
  }

  companyTypeOptions = [
    { name: "Internal", value: "Customer", selected: false },
    { name: "Outsource", value: "Outsource", selected: false }
  ]
  
  companyType: string

  constructor(
    private router: Router,
    private driverService: DriverService,
    private helperService: HelperService,
    private appService: AppService,
    public activatedRoute: ActivatedRoute,
    public addressService: AddressService,
    private authService: AuthService    
  ) { }

  getStatusLabel(status) {
    if (status === DriverStatusEnum.INACTIVE) {
      return 'Disabled';
    } else {
      return status;
    }
  }


  ngOnInit() {

    this.addressService.getCountries().then((countries: any) => {
      this.countryOptions = countries?.map(state => {
        return {
          name: state.name,
          value: state.countryId, // or any other value you want to assign
          selected: false // or any other boolean value based on your logic
        };
      });
    });

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((queryParams) => {
        if (queryParams?.id) {
          this.listTabs.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, '/app/drivers');
        }
      })

    this.appService.$searchTerm
      .pipe(
        untilDestroyed(this),
        debounceTime(500)
      )
      .subscribe((text) => {
        this.search.SearchTerm = text
        this.datatable.refresh({ pageRest: true });
      })

    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.userRoles = user?.roles;
      });
  }

  ngOnDestroy() {
    this.appService.searchTerm = ''
  }

  handleRowClick(row: Driver) {
    this.listTabs.openTab(row.driverId);
  }

  handleRowRightClick(row: Driver) {
    const url = `/app/drivers/edit/${row.driverId}`;
    window.open(url, '_blank');
  }

  companyTypeSelectionUpdated(values) {
    this.search.CompanyTypes = values?.map(r => r.value)
    this.datatable.refresh(true);
  }

  getData = (request) => {
    const filters = omitBy(this.search, (value) => value === null || value === 'null')
    request = Object.assign(
      filters,
      request
    );
    if (request?.SortBy && request?.SortBy !== 'createdTime') {
      this.SortDirection = null
    }
    this.driverService.cancelPendingRequestGetAll();
    return this.driverService.getAll(request);
  };

  countrySelectionUpdated(values) {
    this.search.Countries = flatMap(values?.map(r => r?.name));
    this.search.CountryIds = flatMap(values?.map(r => r?.value));
    this.datatable.refresh(true);
  }

  statusSelectionUpdated(values) {
    this.search.DriverStatus = values?.map(r => r.value)
    this.datatable.refresh(true);
  }

  sortByUpdated(sortBy?: string) {
    if (sortBy && this.SortDirection) {
      this.datatable.setOrderBy(sortBy, this.SortDirection)
    }
    else {
      this.datatable.setOrderBy(null)
    }
  }

  reloadDatatable() {
    if (this.deletedDrivers) {
      this.search.DeletedDrivers = this.deletedDrivers
      //this.search.DriverStatus.push(DriverStatusEnum.DELETED);
    } else {
      // const index = this.search.DriverStatus.indexOf(DriverStatusEnum.DELETED);
      // if (index) {
      //   this.search.DriverStatus.splice(index, 1);
      // }
      delete this.search.DeletedDrivers
    }
    this.datatable.refresh(true);

  }

  onStatusUpdate(driver, status) {
    this.driverService.update(driver.driverId, {
      ...driver,
      status
    }).then((resp) => {
      this.datatable.refresh();
      this.helperService.successMessage(`Driver Successfully ${status === DriverStatusEnum.INACTIVE ? 'Disabled' : 'Enabled'}`);
    }).catch((error) => {
      this.helperService.errorMessage(error);
    })
  }

  onDelete(driver) {
    this.driverService.delete(driver.driverId).then((resp) => {
      this.listTabs.closeTab(`${driver.driverId}`);
      this.datatable.refresh();
      this.helperService.successMessage('Driver Deleted Successfully');
    }).catch((error) => {
      this.helperService.errorMessage(error);
    })
  }

  onDeleteTerminate(driver) {
    this.driverService.permanentDelete(driver.driverId).then((resp) => {
      this.listTabs.closeTab(`${driver.driverId}`);
      this.datatable.refresh();
      this.helperService.successMessage('Driver terminated successfully');
    }).catch((error) => {
      this.helperService.errorMessage(error);
    })
  }

  onRestore(driver) {
    this.driverService.restore(driver.driverId).then((resp) => {
      this.datatable.refresh();
      this.helperService.successMessage('Driver Reactivated Successfully');
    }).catch((error) => {
      this.helperService.errorMessage(error);
    })
  }

  setSelectDrivers(selectedDrivers) {
    this.selectedDriverIds = selectedDrivers
  }

  bulkDeleteDrivers() {
    this.selectedDriverIds?.map((id) => {
      this.listTabs.closeTab(`${id}`);
    })
    this.driverService.bulkDelete(this.selectedDriverIds).then((res) => {
      this.datatable.refresh(true);
      this.helperService.successMessage('Drivers Deleted Successfully');
    })
  }

}
