import { BaseModel } from '@models/base.model';

export interface CustomerContact  extends BaseModel{
    customerContactId?: number;
    outsourceCompanyId?: number;
    customerId?: number;
    firstName?: string;
    lastName?: string;
    phone?: string;
    phoneNumber?: string; //this is temporary field 
    phoneExtension?: string; //this is temporary field 
    phoneCountryCode?: number;
    phoneCountryIso?: string;
    cell?: string;
    email?: string;
    isPrimary?: boolean;
    active?: boolean;
    imageUrl?: string;
    location?: string;
    countryId?: number;
    state?: string;
    city?: string;
    zip?: string;
    creationType?: ContactCreationType
}

export enum ContactCreationType {
    Legacy = 0,
    Default = 1
}

export interface ValidateContact {
    excludeCustomerId: number;
    existedEmail: boolean;
    existedPhone: boolean;
    existedProspect?: any;
}
